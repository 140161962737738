import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

import LegalCover from "../components/legal/legal-cover.js"

const Disclaimer = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <SEO title="Disclaimer - Credit 101" keywords={[`credit`, `card`, `score`, `build`]} description="Contents on Credit 101 only represents the site owner's personal experiences and viewpoints. Articles on Credit 101 are of opinion and general nature and should not be relied upon for individual circumstances, nor should they be taken as investment, financial planning, tax, or legal advice."/>
      <LegalCover title="Disclaimer"></LegalCover>
      <div className="legal-content p-4">
        <p>Contents on Credit 101 only represents the site owner's personal experiences and viewpoints. Articles on Credit 101 are of opinion and general nature and should not be relied upon for individual circumstances, nor should they be taken as investment, financial planning, tax, or legal advice. Please seek professional advice before making all credit decisions.</p>
        <p>The information provided by Credit 101 ("We", "Us", or "Our") on credit101.net ("Site") is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, availablity or completeness of any information on the Site. </p>
        <p>"Client", "You" and "Your" refers to you, the person accessing this website and accepting the terms and conditions, privacy policy, and this disclaimer. Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site is solely at your own risk.</p>
        <p>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availablity, or completeness by us.</p>
      </div>
    </Layout>
  )
}

export default Disclaimer
